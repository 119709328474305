<template>
<div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
    <side-bar></side-bar>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper">

        <div class="page-header">
            <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-account-multiple"></i>
            </span> Clients Stats
            </h3>
            <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
            </ul>
            </nav>
        </div>

        <div class="row">
            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Total Clients</h4>
                        <h2 class="mb-0" v-if="clients_data && clients_data.client_stats && clients_data.client_stats.total_clients">{{clients_data.client_stats.total_clients}}</h2>
                        <!-- <h6 class="card-text">Increased by 60%</h6> -->
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Active Clients</h4>
                        <h2 class="mb-0" v-if="clients_data && clients_data.client_stats && clients_data.client_stats.active_clients">{{clients_data.client_stats.active_clients}}</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-success card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-book-plus mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Inactive Clients</h4>
                                    <h2 class="mb-0" v-if="clients_data && clients_data.client_stats && clients_data.client_stats.inactive_clients">{{clients_data.client_stats.inactive_clients}}</h2>
                                    <!-- <h6 class="card-text">Decreased by 10%</h6> -->
                     </div>
                 </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-danger card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Last 7 Days
                                    </h4>
                                    <h2 class="mb-0" v-if="clients_data && clients_data.client_stats && clients_data.client_stats.new_clients_last_7_days">{{clients_data.client_stats.new_clients_last_7_days}}</h2>
                                </div>
                            </div>
                        </div>


            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                      <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                      <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Last 30 Days</h4>
                      <h2 class="mb-0" v-if="clients_data && clients_data.client_stats && clients_data.client_stats.new_clients_last_30_days">{{clients_data.client_stats.new_clients_last_30_days}}</h2>
                </div>
              </div>
            </div>
        </div>
        <br/><br/>
        <div class="row">
            <div class="col-lg-5 card ms-2">
                <div class="card-body">
                    <h4>Last 7 Days Signup</h4>
                    <div class="table-responsive" v-if="clients_data && clients_data.new_clients_last_7_days">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in clients_data.new_clients_last_7_days" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 card ms-5 me-2">
                <div class="card-body">
                    <h4>Last 30 Days Signup</h4>
                    <div class="table-responsive" v-if="clients_data && clients_data.new_clients_last_30_days">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in clients_data.new_clients_last_30_days" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>
        <div class="page-header">
            <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-buffer"></i>
            </span> Subscriptions Stats
            </h3>
            <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
            </ul>
            </nav>
        </div>

        <div class="row">
            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Active Subscriptions</h4>
                        <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.active_subscriptions">{{subscriptions_data.active_subscriptions}}</h2>
                        <!-- <h6 class="card-text">Increased by 60%</h6> -->
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Inactive Subscriptions</h4>
                        <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.inactive_subscriptions">{{subscriptions_data.inactive_subscriptions}}</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-success card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-book-plus mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Non-Subscriber</h4>
                                    <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.non_subscribed_clients">{{subscriptions_data.non_subscribed_clients}}</h2>
                                    <!-- <h6 class="card-text">Decreased by 10%</h6> -->
                     </div>
                 </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-danger card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Avg. Sub. Period
                                    </h4>
                                    <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.average_subscription_period">{{subscriptions_data.average_subscription_period}} Days</h2>
                                </div>
                            </div>
                        </div>
        </div>

        <div class="row">
            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Due Today</h4>
                        <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.due_today">{{subscriptions_data.due_today}}</h2>
                        <!-- <h6 class="card-text">Increased by 60%</h6> -->
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                <div class="card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                        <h4 class="mb-3"><i class="mdi mdi-book-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Due in Next 3 Days</h4>
                        <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.due_in_3_days">{{subscriptions_data.due_in_3_days}}</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-success card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-book-plus mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Due in Next 7 Days</h4>
                                    <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.due_in_7_days">{{subscriptions_data.due_in_7_days}}</h2>
                                    <!-- <h6 class="card-text">Decreased by 10%</h6> -->
                     </div>
                 </div>
            </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-danger card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Due by 1 Day
                                    </h4>
                                    <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.due_by_1_day">{{subscriptions_data.due_by_1_day}}</h2>
                                </div>
                            </div>
                        </div>

            <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-danger card-img-holder text-white">
                                <div class="card-body">
                                    <img src="@/assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                                    <h4 class="mb-3"><i class="mdi mdi-account-multiple mdi-24px float-right"></i>&nbsp;&nbsp;&nbsp; Due by 3 Day
                                    </h4>
                                    <h2 class="mb-0" v-if="subscriptions_data && subscriptions_data.due_by_3_days">{{subscriptions_data.due_by_3_days}}</h2>
                                </div>
                            </div>
                        </div>
        </div>

        <br/><br/>
        <div class="row">
            <div class="col-lg-5 card ms-2">
                <div class="card-body">
                    <h4>Subscription Due Today</h4>
                    <div class="table-responsive"v-if="subscriptions_data && subscriptions_data.list_of_clients_subscription_due_today">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in subscriptions_data.list_of_clients_subscription_due_today" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 card ms-5 me-2">
                <div class="card-body">
                    <h4>Subscription in Next 3 Days</h4>
                    <div class="table-responsive" v-if="subscriptions_data && subscriptions_data.list_of_clients_subscription_due_in_3_days">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in subscriptions_data.list_of_clients_subscription_due_in_3_days" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <br/><br/>
        <div class="row">
            <div class="col-lg-5 card ms-2">
                <div class="card-body">
                    <h4>Subscription Due by 1 Day</h4>
                    <div class="table-responsive" v-if="subscriptions_data && subscriptions_data.list_of_clients_subscription_due_by_1_days">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in subscriptions_data.list_of_clients_subscription_due_by_1_days" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 card ms-5 me-2">
                <div class="card-body">
                    <h4>Subscription due by 3 days</h4>
                    <div class="table-responsive" v-if="subscriptions_data && subscriptions_data.list_of_clients_subscription_due_by_3_days">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Business Name</td>
                                    <td>Email</td>
                                    <td>Mobile</td>
                                    <td>City</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in subscriptions_data.list_of_clients_subscription_due_by_3_days" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{obj.business_name}}</td>
                                    <td>{{obj.email}}</td>
                                    <td>{{obj.mobile}}</td>
                                    <td>{{obj.city}} - {{obj.postal_code}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <br/><br/>
        <div class="page-header">
            <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-airplane"></i>
            </span> Country Data({{city_data.total_served_countries}})
            </h3>
            <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
            </ul>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-5 card ms-2">
                <div class="card-body">
                    <h4>Top Country</h4>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Country Name</td>
                                    <td>Clients</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj,i) in city_data.top_countries" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{getCountryName(obj.country)}}</td>
                                    <td>{{obj.client_count}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 card ms-5 me-2">
                <div class="card-body">
                    <h4>Countries Served</h4>

                </div>
            </div>
        </div>


    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->
    <Footer/>
        <!-- partial -->
    </div>
    <!-- main-panel ends -->
    </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { mapActions} from "vuex";
import * as Sc from "../../../assets/vendors/chart.js/Chart.min.js"
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"

export default {
    name:'Front',
    title: string.PAGE_TITLE_DASHBOARD,
    components: {
        NavBar,
        SideBar,
        Multiselect,
        Footer,
    },
    data() {
    const now = new Date()
    const current_year = now.getFullYear() - 5

    return {
      clients_data : {"total_clients": 0, "active_clients": 0, "inactive_clients": 0,
                      "new_clients_last_7_days": 0, "new_clients_last_30_days": 7, "new_clients_last_7_days": [],
                      "new_clients_last_30_days":[], "last_twelve_month_data":[]},
      subscriptions_data : {"active_subscriptions":0, "inactive_subscriptions":0, "non_subscribed_clients":0,
                            "average_subscription_period":0,"due_today":0,"due_in_3_days":0,"due_in_7_days":0,
                            "due_by_1_day":0,"due_by_3_days":0, "list_of_clients_subscription_due_today": [],
                            "list_of_clients_subscription_due_in_3_days": [], "list_of_clients_subscription_due_by_1_days": [],
                            "list_of_clients_subscription_due_by_3_days": []},
      city_data : {},
      statistics_data:[],
      department_statistics_data:[],
      earning_statistics_data:[],
      doctor_statistics_data:[],
      statistics_options:[{"display_name":"Last 3 month","api_value":3}, {"display_name":"Last 6 month","api_value":6}, {"display_name":"Last 12 month","api_value":12}, {"display_name":"Last 2 Year","api_value":24},  {"display_name":"Last 3 Year","api_value":36},  {"display_name":"Last 4 Year","api_value":48},  {"display_name":"Last 5 Year","api_value":60}],
      statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      department_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      earnings_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      doctor_statistics_selected_options:{"display_name":"Last 3 month","api_value":3},
      current_year:current_year,
      myChart:null,
      response:{},
    }
    },
    mounted() {
        this.getDashboard();
        //  this.updateFilters();
    },
    methods:{
        ...mapActions("hospital",["getMasterAdminDashboardAPI"]),

        getCountryName(name){
            if(name != null && name != '') {
                return name
            } else {
                return "Not Set"
            }
        },

        getDashboard(){
        console.log(this.statistics_selected_options.api_value)
         var bodyFormData = new FormData();
         bodyFormData.append('dashboard_filter', this.statistics_selected_options.api_value)
         bodyFormData.append('dashboard_department_filter', this.department_statistics_selected_options.api_value)
         bodyFormData.append('dashboard_earning_filter', this.earnings_statistics_selected_options.api_value)
         bodyFormData.append('dashboard_doctor_filter', this.doctor_statistics_selected_options.api_value)
          this.getMasterAdminDashboardAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.response = response,
              this.clients_data = response.clients
              this.subscriptions_data = response.subscriptions
              this.city_data = response.geographical_data
            }
          });
        },
        setChartDetails() {

        let statics_label = []
        let statics_data_opd = []
        let statics_data_ipd = []
        let statics_data_patients = []
        let statics_data_appointments = []
        this.statistics_data.forEach((statistics_obj) => {
              const results = Object.keys(statistics_obj)
              statics_label.push(results)
              statics_data_opd.push(Object.values(statistics_obj)[0].total_opd)
              statics_data_ipd.push(Object.values(statistics_obj)[0].total_ipd)
              statics_data_patients.push(Object.values(statistics_obj)[0].total_patient)
              statics_data_appointments.push(Object.values(statistics_obj)[0].total_appointments)
        })
        console.log(statics_data_opd)

        Chart.defaults.global.legend.labels.usePointStyle = true;
              var ctx = document.getElementById('visit-sale-chart').getContext("2d");

              var gradientStrokeViolet = ctx.createLinearGradient(0, 0, 0, 181);
              gradientStrokeViolet.addColorStop(0, 'rgba(218, 140, 255, 1)');
              gradientStrokeViolet.addColorStop(1, 'rgba(154, 85, 255, 1)');
              var gradientLegendViolet = 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))';

              var gradientStrokeBlue = ctx.createLinearGradient(0, 0, 0, 360);
              gradientStrokeBlue.addColorStop(0, 'rgba(54, 215, 232, 1)');
              gradientStrokeBlue.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendBlue = 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))';

              var gradientStrokeRed = ctx.createLinearGradient(0, 0, 0, 300);
              gradientStrokeRed.addColorStop(0, 'rgba(255, 191, 150, 1)');
              gradientStrokeRed.addColorStop(1, 'rgba(254, 112, 150, 1)');
              var gradientLegendRed = 'linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))';

              var gradientStrokeGreen = ctx.createLinearGradient(0, 0, 0, 300);
              gradientStrokeGreen.addColorStop(0, 'rgba(0, 63, 92, 1)');
              gradientStrokeGreen.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendGreen = 'linear-gradient(to right, rgba(0, 63, 92, 1), rgba(177, 148, 250, 1))';

              if(ctx.myChart != null){
                ctx.myChart.destroy()
              }

              this.myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: statics_label,
                    datasets: [
                      {
                        label: "OPDs",
                        borderColor: gradientStrokeViolet,
                        backgroundColor: gradientStrokeViolet,
                        hoverBackgroundColor: gradientStrokeViolet,
                        legendColor: gradientLegendViolet,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_opd
                      },
                      {
                        label: "IPDs",
                        borderColor: gradientStrokeGreen,
                        backgroundColor: gradientStrokeGreen,
                        hoverBackgroundColor: gradientStrokeGreen,
                        legendColor: gradientLegendGreen,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_ipd
                      },
                      {
                        label: "Patients",
                        borderColor: gradientStrokeRed,
                        backgroundColor: gradientStrokeRed,
                        hoverBackgroundColor: gradientStrokeRed,
                        legendColor: gradientLegendRed,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_patients
                      },
                      {
                        label: "Appointments",
                        borderColor: gradientStrokeBlue,
                        backgroundColor: gradientStrokeBlue,
                        hoverBackgroundColor: gradientStrokeBlue,
                        legendColor: gradientLegendBlue,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: statics_data_appointments
                      }
                  ]
                },
                options: {
                  responsive: true,
                  legend: false,
                  legendCallback: function(chart) {
                    var text = [];
                    text.push('<ul>');
                    for (var i = 0; i < chart.data.datasets.length; i++) {
                        text.push('<li><span class="legend-dots" style="background:' +
                                   chart.data.datasets[i].legendColor +
                                   '"></span>');
                        if (chart.data.datasets[i].label) {
                            text.push(chart.data.datasets[i].label);
                        }
                        text.push('</li>');
                    }
                    text.push('</ul>');
                    return text.join('');
                  },
                  scales: {
                      yAxes: [{
                          ticks: {
                              display: false,
                              min: 0,
                              stepSize: 20,
                              max: 80
                          },
                          gridLines: {
                            drawBorder: false,
                            color: 'rgba(235,237,242,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                            display:false,
                            drawBorder: false,
                            color: 'rgba(0,0,0,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          },
                          ticks: {
                              padding: 20,
                              fontColor: "#9c9fa6",
                              autoSkip: true,
                          },
                          categoryPercentage: 0.5,
                          barPercentage: 0.5
                      }]
                    }
                  },
                  elements: {
                    point: {
                      radius: 0
                    }
                  }
              })
              document.getElementById("visit-sale-chart-legend").html = (this.myChart.generateLegend());
        },
        setDepartmentChartDetails() {
        console.log("sdadadstatics_data_opd")

        let department_statics_label = []
        let department_statics_data_opd = []
        let department_statics_data_appointments = []
        this.department_statistics_data.forEach((department_statistics_obj) => {
              const results = Object.keys(department_statistics_obj)
              department_statics_label.push(results)
              department_statics_data_opd.push(Object.values(department_statistics_obj)[0].total_opd)
              department_statics_data_appointments.push(Object.values(department_statistics_obj)[0].total_appointments)
        })
        console.log("sdadadstatics_data_opd")
        console.log(department_statics_data_opd)

        Chart.defaults.global.legend.labels.usePointStyle = true;
              var ctx = document.getElementById('department-chart').getContext("2d");

              var gradientStrokeViolet = ctx.createLinearGradient(0, 0, 0, 181);
              gradientStrokeViolet.addColorStop(0, 'rgba(218, 140, 255, 1)');
              gradientStrokeViolet.addColorStop(1, 'rgba(154, 85, 255, 1)');
              var gradientLegendViolet = 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))';

              var gradientStrokeBlue = ctx.createLinearGradient(0, 0, 0, 360);
              gradientStrokeBlue.addColorStop(0, 'rgba(54, 215, 232, 1)');
              gradientStrokeBlue.addColorStop(1, 'rgba(177, 148, 250, 1)');
              var gradientLegendBlue = 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))';

              if(ctx.myChart != null){
                ctx.myChart.destroy()
              }

              this.myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: department_statics_label,
                    datasets: [
                      {
                        label: "OPDs",
                        borderColor: gradientStrokeViolet,
                        backgroundColor: gradientStrokeViolet,
                        hoverBackgroundColor: gradientStrokeViolet,
                        legendColor: gradientLegendViolet,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: department_statics_data_opd
                      },
                      {
                        label: "Appointments",
                        borderColor: gradientStrokeBlue,
                        backgroundColor: gradientStrokeBlue,
                        hoverBackgroundColor: gradientStrokeBlue,
                        legendColor: gradientLegendBlue,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 1,
                        fill: 'origin',
                        data: department_statics_data_appointments
                      }
                  ]
                },
                options: {
                  responsive: true,
                  legend: false,
                  legendCallback: function(chart) {
                    var text = [];
                    text.push('<ul>');
                    for (var i = 0; i < chart.data.datasets.length; i++) {
                        text.push('<li><span class="legend-dots" style="background:' +
                                   chart.data.datasets[i].legendColor +
                                   '"></span>');
                        if (chart.data.datasets[i].label) {
                            text.push(chart.data.datasets[i].label);
                        }
                        text.push('</li>');
                    }
                    text.push('</ul>');
                    return text.join('');
                  },
                  scales: {
                      yAxes: [{
                          ticks: {
                              display: false,
                              min: 0,
                              stepSize: 20,
                              max: 80
                          },
                          gridLines: {
                            drawBorder: false,
                            color: 'rgba(235,237,242,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                            display:false,
                            drawBorder: false,
                            color: 'rgba(0,0,0,1)',
                            zeroLineColor: 'rgba(235,237,242,1)'
                          },
                          ticks: {
                              padding: 20,
                              fontColor: "#9c9fa6",
                              autoSkip: true,
                          },
                          categoryPercentage: 0.5,
                          barPercentage: 0.5
                      }]
                    }
                  },
                  elements: {
                    point: {
                      radius: 0
                    }
                  }
              })
              document.getElementById("department-chart-legend").html = (this.myChart.generateLegend());
        },

        setEarningsChartDetails() {
            let earnings_statics_label = []
            let earnings_statics_data = []

            this.earning_statistics_data.forEach((earning_statistics_obj) => {
                const results = Object.keys(earning_statistics_obj)
                earnings_statics_label.push(results[0])
                earnings_statics_data.push(Object.values(earning_statistics_obj)[0].total_earning)
            })

            var myChart = document.getElementById('earnings-chart');
            console.log(myChart);
            var ctx = myChart.getContext("2d");

            if(ctx.myChart != null){
                ctx.myChart.destroy()
            }

            var areaData = {
                labels: earnings_statics_label,
                datasets: [{
                  label: 'Earnings',
                  data: earnings_statics_data,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                  ],
                  borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                  ],
                  borderWidth: 2,
                  fill: true, // 3: no fill
                }]
            };

            var areaOptions = {
                plugins: {
                    filler: {
                        propagate: true
                    }
                }
            }

            var earningChart = ctx;
            var areaChart = new Chart(earningChart, {
                type: 'line',
                data: areaData,
                options: areaOptions
            });
        },

        setDoctorChartDetails() {
            let doctor_statics_label = []
            let doctor_statics_data = []

            this.doctor_statistics_data.forEach((doctor_statistics_obj) => {
                const results = Object.keys(doctor_statistics_obj)
                doctor_statics_label.push(results[0])
                doctor_statics_data.push(Object.values(doctor_statistics_obj)[0].opd_per)
            })

            var doctorChart = document.getElementById('doctor-chart');
            console.log(doctorChart);
            var ctx = doctorChart.getContext("2d");

            if(ctx.doctorChart != null){
                ctx.doctorChart.destroy()
            }

            var doctorChartData = {
                datasets: [{
                    data: doctor_statics_data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                }],
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: doctor_statics_label
              };

              var doctorChartOptions = {
                responsive: true,
                animation: {
                  animateScale: true,
                  animateRotate: true
                }
              };

            var doctorChart = new Chart(ctx, {
                type: 'pie',
                data: doctorChartData,
                options: doctorChartOptions
            });
        },
        updateFilters() {
            while( this.current_year >= 2023 ) {
              this.statistics_options.push({"display_name":this.current_year,"api_value":this.current_year})
              this.current_year = this.current_year - 1
            }
        }
    }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>